import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import { SEO } from '../components/seo';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import {
  topicImage,
  dots,
  articleContent,
} from '../components/layout.module.css';
import SuggestedArticle from '../components/SuggestedArticle';

// Template for articles, renders featured image and title at the top followed by content and then CTA

function Page({ data, pageContext }) {
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title,
    description: page.frontmatter.description,
  };

  const image = getImage(data.mdx.frontmatter.featImg);
  if (image !== undefined) {
    // if the image prop is included with the mdx, render it. Otherwise don't try to render the image
    return (
      <Layout meta={meta} pageTitle={page.frontmatter.title}>
        <div className={articleContent}>
          <h1>{page.frontmatter.title}</h1>
          <GatsbyImage
            className={topicImage}
            image={image}
            alt={data.mdx.frontmatter.altImg}
          />
          <MDXRenderer>{page.body}</MDXRenderer>
          <StaticImage
            className={dots}
            src="../images/dots-light.png"
            alt="dots"
          />
          <SuggestedArticle
            suggestedArticle={data.mdx.frontmatter.suggestedArticle}
          />
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout meta={meta} pageTitle={page.frontmatter.title}>
        <div className={articleContent}>
          <h1>{page.frontmatter.title}</h1>
          <MDXRenderer>{page.body}</MDXRenderer>
          <StaticImage
            className={dots}
            src="../images/dots-light.png"
            alt="dots"
          />
          <SuggestedArticle
            suggestedArticle={data.mdx.frontmatter.suggestedArticle}
          />
        </div>
      </Layout>
    );
  }
}

// <Link className={cta} to="/buzhou">{ data.mdx.frontmatter.cta }</Link>
// Removed for SalamMaaAllah

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        suggestedArticle
        excerpt
        metaDesc
        category
        featImg {
          childImageSharp {
            gatsbyImageData
          }
        }
        altImg
        # cta
        outreach
        ogDesc
        ogImage
        twDesc
        twImage
        slug
        template
      }
      body
    }
  }
`;

export default Page;

export const Head = ({ pageContext }) => <SEO pageContext={pageContext} />;
