import * as React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  suggestedArticle,
  linkTextIcon,
} from '../components/layout.module.css';

const SuggestedArticle = (data) => {
  // retrieve all articles
  const query = useStaticQuery(graphql`
    query {
      allMdx(filter: { frontmatter: { template: { eq: "lp" } } }) {
        nodes {
          frontmatter {
            suggestedArticle
            slug
            template
            title
            altImg
            featImg {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  // Find the article that matches the suggestedArticle prop and set image
  let response = query.allMdx.nodes.find(
    (x) => x.frontmatter.slug === data.suggestedArticle,
  );
  const image = getImage(response.frontmatter.featImg);

  return (
    <div className={suggestedArticle}>
      <h2>مواضيع ذات صلة</h2>
      <blockquote>
        <Link to={'/' + response.frontmatter.slug}>
          <div>
            {image && (
              <GatsbyImage image={image} alt={response.frontmatter.altImg} />
            )}
            <div className={linkTextIcon}>
              <h3>{response.frontmatter.title}</h3>
              <i />
            </div>
          </div>
        </Link>
      </blockquote>
    </div>
  );
};

export default SuggestedArticle;
